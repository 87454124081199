.pagination-box
    margin-top: 20px
    &__col
        display: flex
        align-items: center
        justify-content: flex-end
        @media (max-width: 991px)
            flex-direction: column
            align-items: flex-end
    &__dropdown
        background: #FFFFFF
        border: 1px solid #DEDEDF
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.153363)
        border-radius: 4px
        height: 16px
        padding: 0 10px
        border: 0
        font-family: 'Roboto', sans-serif
        font-size: 9px !important
        color: #000000 !important
        @media (max-width: 991px)
            margin-right: 0
            margin-bottom: 25px
        & .item
            font-size: 9px !important
            color: #000000 !important
    &__pages
        margin-left: 25px !important
        height: 16px !important
        min-height: 16px !important
        max-height: 16px !important
        padding: 0 !important
        & .item:first-child, & .item:last-child
            display: none !important
        & .item
            height: 16px !important
            width: 32px !important
            line-height: 16px !important
            padding: 0 !important
            font-size: 9px !important
            color: #1B1C1D !important
            display: flex
            align-items: center
            justify-content: center
            &.activated
                color: #FFFFFF !important
                background: #FF6D4A !important