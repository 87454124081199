.menu-box
    &__item
        margin-top: 30px
        padding: 0 !important
        &::before
            display: none
        &:first-child
            margin-top: 0
    &__link
        text-align: center
        color: #53498f
        display: block
        transition: color 0.2s ease-in-out
        position: relative
        span
            font-weight: 700
            font-size: 10px
            line-height: 12px
            padding-top: 5px
            color: #53498f
            transition: color 0.2s ease-in-out
            display: block
        svg
            width: 35px
            height: 35px
            fill: #53498f
            display: inline-flex
            margin: 0 auto
            transition: fill 0.2s ease-in-out
        &::before
            content: ''
            width: 9px
            height: 44px
            background: #FF6D4A
            position: absolute
            left: 0
            transform: translate(-50%, -50%)
            top: 50%
            transition: opacity 0.2s ease-in-out
            opacity: 0
            border-top-right-radius: 9px
            border-bottom-right-radius: 9px
        &.activated
            transition: color 0.2s ease-in-out
            color: #ff8972
            span
                color: #ff8972
                transition: color 0.2s ease-in-out
            svg
                fill: #ff8972
                transition: fill 0.2s ease-in-out
            &::before
                opacity: 1
        &:hover
            transition: color 0.2s ease-in-out
            color: #ff8972
            span
                color: #ff8972
                transition: color 0.2s ease-in-out