@font-face
    font-family: 'Lato'
    src: url('fonts/Lato/lato-regular.eot')
    src: url('fonts/Lato/lato-regular.eot?#iefix') format('embedded-opentype'), url('fonts/Lato/lato-regular.woff2') format('woff2'), url('fonts/Lato/lato-regular.woff') format('woff'), url('fonts/Lato/lato-regular.ttf') format('truetype')
    font-style: normal
    font-weight: normal
    text-rendering: optimizeLegibility

@font-face
    font-family: 'Lato'
    src: url('fonts/Lato/Lato-Semibold.eot')
    src: url('fonts/Lato/Lato-Semibold.eot?#iefix') format('embedded-opentype'), url('fonts/Lato/Lato-Semibold.woff2') format('woff2'), url('fonts/Lato/Lato-Semibold.woff') format('woff'), url('fonts/Lato/Lato-Semibold.ttf') format('truetype')
    font-style: normal
    font-weight: 600
    text-rendering: optimizeLegibility

@font-face
    font-family: 'Lato'
    src: url('fonts/Lato/lato-bold.eot')
    src: url('fonts/Lato/lato-bold.eot?#iefix') format('embedded-opentype'), url('fonts/Lato/lato-bold.woff2') format('woff2'), url('fonts/Lato/lato-bold.woff') format('woff'), url('fonts/Lato/lato-bold.ttf') format('truetype')
    font-style: normal
    font-weight: bold
    text-rendering: optimizeLegibility

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap')
@import './sass/main-box'
@import './sass/aside-box'
@import './sass/grid-box'
@import './sass/menu-box'
@import './sass/btn-box'
@import './sass/table-box'
@import './sass/pagination-box'
@import './sass/form-box'
@import './sass/bradcrumb-box'
@import './sass/register_client'