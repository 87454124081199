.form-box
    width: 100%
    // max-width: 530px
    background: #fff
    padding: 40px 30px 20px
    border-radius: 6px
    &_border-radius-rb
        border-radius: 0 6px 6px 6px
    &__inner
        display: flex
        justify-content: space-between
        @media (max-width: 991px)
            flex-direction: column
    &__column
        max-width: 530px
        flex-grow: 1
    &__demo
        display: flex
        justify-content: center
    &__label
        margin-bottom: 4px
        display: block
        font-weight: bold
    &__field
        margin-bottom: 30px
        &_required
            position: relative
            &::before
                content: '*'
                position: absolute
                color: #FF6D4A
                font-size: 24px
                right: 0
                top: 4px
                font-weight: bold
        &-action
            display: flex
            align-items: center
            justify-content: flex-start
            span
                margin-right: 15px
                margin-left: 15px
                display: block
    &__segment
        margin-top: 80px !important
        padding: 0 !important
        border: 0 !important
        box-shadow: none !important
    &__input
        display: flex
        align-items: center
        & input
            border: 0 !important
            border-bottom: 1px solid #868686 !important
            font-weight: 500
            font-size: 14px
            color: #323C47 !important
            padding: 0 0 14px 0 !important
            border-radius: 0 !important
            &::-webkit-input-placeholder
                color: #868686
            &:-moz-placeholder
                color: #868686
                opacity: 1
            &::-moz-placeholder
                color: #868686
                opacity: 1
            &:-ms-input-placeholder
                color: #868686
            &::placeholder
                color: #868686
        & .input
            width: 100%
    &__select
        max-width: 156px
        width: 100%
        font-weight: 500
        font-size: 14px
        color: #323C47
        height: 28px !important
        padding: 0 !important
        min-height: 28px !important
        max-height: 28px !important
        line-height: auto !important
        i
            padding: 8px !important
        &_full
            width: 100%
            max-width: 100%
        & .selection.dropdown
            width: 100%
        &::-webkit-input-placeholder
            color: #323C47
        &:-moz-placeholder
            color: #323C47
            opacity: 1
        &::-moz-placeholder
            color: #323C47
            opacity: 1
        &:-ms-input-placeholder
            color: #323C47
        &::placeholder
            color: #323C47
    &__multiselect
        max-width: 156px !important
        width: 100% !important
        font-weight: 500 !important
        font-size: 14px !important
        color: #323C47 !important
        height: 28px !important
        padding: 0 !important
        min-height: 28px !important
        max-height: 28px !important
        line-height: auto !important
        display: flex !important
        align-items: center !important
        background: #F5F8FA !important
        border-radius: 4px 4px 4px 4px !important
        border: 0 !important
        & > .divider.default.text
            font-weight: 700 !important
            font-size: 11px !important
            color: #5B5C5C !important
        & > i
            background: #E0E7EB
            height: 100% !important
            display: flex
            align-items: center
            justify-content: center
            width: 25px !important
            right: 0 !important
            top: 0 !important
            padding: 0 !important
            margin: 0 !important
            margin-left: auto !important
            border-radius: 0 4px 4px 0 !important
            color: #5B5C5C !important
        &_selected
            background: #5E5C71 !important
            & > .divider.default.text
                color: #fff !important
            & > i
                background: #49475A !important
                color: #fff !important
    &__payment
        display: flex
        align-items: center
        &-field
            margin-bottom: 2rem
            &:last-child
                margin-bottom: 3rem
            & label
                font-size: 1.2rem
                margin-bottom: 0
                margin-right: 1rem
        &-input
            & input
                max-width: 80px !important
                border: 0 !important
                border-radius: 0 !important
                border-bottom: 1px solid #868686 !important
                margin-right: 0.3rem !important
                padding: 0.3rem 0.5rem !important
        &-error
            position: absolute
            bottom: 5.5rem
            margin-left: 2.4rem
            color: #E64646
            & div
                margin-bottom: 1rem
            & div:last-child
                margin-bottom: 0
        &-info
            position: absolute
            bottom: 5.5rem
            margin-left: 2.4rem