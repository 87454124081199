.table-box
    border: 0 !important
    padding-bottom: 25px
    &__wrapper
        overflow: auto
        margin-top: 15px
    th
        font-weight: 500 !important
        font-size: 11px !important
        line-height: 13px !important
        text-align: center !important
        text-transform: uppercase !important
        color: #979CA1 !important
        background: #fff !important
        border: 0 !important
        padding: 15px 5px !important
    td
        font-weight: 500 !important
        font-size: 12px !important
        line-height: 14px !important
        text-align: center !important
        color: #323C47 !important
        border: 0 !important
        padding: 15px 5px !important
        border-bottom: 1px solid #ecf0f5 !important
        &.table-box__td_empty
            text-align: left !important
            padding-left: 15px !important
    td a
        font-weight: 500 !important
        font-size: 12px !important
        line-height: 14px !important
        text-align: center !important
        color: #323C47 !important
        border: 0 !important
    tbody
        tr
            height: 73px
    tr:hover, tr.table-box__row-selected
        background: rgba(233, 239, 244, 0.48)
        position: relative
        td::before
            content: ''
            position: absolute
            left: 0
            top: 0
            height: 100%
            width: 4px
            background: #FF6D4A