.grid-box
    margin: 0 !important
    flex-wrap: nowrap
    &__header
        display: flex
        align-items: center
        justify-content: space-between
    &__space
        margin-top: 15px
        overflow: auto
        height: calc( 100vh -  195px )
        &_small
            height: calc( 100vh -  260px )
    &__area
        display: flex !important
        align-items: center
        @media (max-width: 991px)
            width: 100%
            min-width: 100%
            flex-grow: 1
            flex-direction: column !important
            align-items: start
            & .btn-box, & .form-box__select
                margin-bottom: 15px
        & .right
            justify-content: flex-end
    &__payment
        &-btn
            margin-right: 1rem !important
            background-color: #FFFFFF !important
            border: 1px solid #FF6D4A !important
            @media (max-width: 991px)
                margin-right: 0 !important
                margin-bottom: 1rem !important
            span
                white-space: nowrap
                color: #FF6D4A
                background-color: inherit
            &:hover
                background-color: #FF6D4A !important
                span
                    color: #FFFFFF !important
    &__actions
        align-items: center
        justify-content: space-between
        width: 100%
        margin: 0 !important
        flex-grow: 1
        flex-wrap: nowrap !important
    &__action
        display: flex !important
        align-items: center !important
        &_btns
            width: 100% !important
            padding-left: 0 !important
        &-btn
            margin-right: 15px
            @media (max-width: 991px)
                margin-right: 0
                margin-bottom: 15px
            &:last-child
                margin-right: 0
        &-search
            width: 290px !important
            padding: 0 !important
            display: flex
            align-items: flex-end
            justify-content: flex-end
            @media (max-width: 991px)
                margin-left: 0
                align-items: flex-start
                justify-content: flex-start
            input
                width: 100%
                border: 1px solid #DEDEDF
                border-radius: 4px
                height: 28px
                display: block
                margin-left: auto
    &_full
        width: 100% !important
    &_grow
        flex-grow: 1
    &__right
        flex-grow: 1
        max-width: calc(100% - 95px)
        margin-right: 0 !important
        padding-right: 0 !important
        padding-left: 15px !important 
    &__search
        margin-left: auto
        width: 294px !important
        @media (max-width: 991px)
            width: 100% !important
            flex-grow: 1 !important