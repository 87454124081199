.aside-box
    width: 80px !important
    min-width: 80px !important
    max-width: 80px !important
    flex-shrink: 0 !important
    padding: 0 !important
    margin: 0 !important
    border: 0 !important
    box-shadow: none !important
    &__menu
        height: 100vh
        background: #FAFBFC !important
        width: 80px !important
        max-width: 80px !important
        border: 0 !important
        border-right: 1px solid #e8e8e8 !important
        margin: 0 !important
        box-shadow: none !important
        border-radius: 0 !important
    &__logo
        padding: 15px 0 !important
        margin: 0 auto
        text-align: center
        a
            display: flex
            align-items: center
            justify-content: center