.client
	display: flex
	justify-content: space-between
	&_buttons
		flex-grow: 1
		margin-right: 10px
	&_icons
		display: flex
		height: 36px
		align-items: center
		i:first-child
			margin-right: 10px

.register_line, .test_line
	display: flex
	align-items: center
	margin-bottom: 30px
