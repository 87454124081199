.btn-box
    height: 28px !important
    background: #FF6D4A !important
    border-radius: 4px !important
    overflow: hidden
    padding: 0 !important
    display: inline-flex !important
    align-items: center
    justify-content: center
    flex-wrap: nowrap !important
    &_large
        height: 36px !important
    &_right
        margin-left: auto !important
        display: flex !important
    &_icon
        svg
            flex-shrink: 0
        &-plus
            width: 14px
            height: 14px
            fill: #fff
        &-trash
            width: 18px
            height: 18px
            fill: #FF6D4A
        &-bg
            display: flex
            align-items: center
            flex-shrink: 0
            &-area
                height: 100%
                padding: 0 6px
                background: #ff917a
                box-sizing: border-box
                display: flex
                align-items: center
                justify-content: center
        &-left-arrow
            width: 10px
            height: 7px
            fill: #A8AAB7
        &-right-arrow
            width: 10px
            height: 7px
            fill: #A8AAB7
    &_fill
        span
            font-weight: 700
            font-size: 14px
            color: #FFFFFF
            padding: 0 18px
    &_full
        width: 100%
    &_simple
        background: #FFFFFF !important
        border: 1px solid #FF684A !important
        span
            font-weight: 600
            font-size: 14px
            color: #FF6D4A
            padding: 0 18px
        &-icon
            padding: 0 6px
            display: flex
            align-items: center
            flex-shrink: 0
    &_gray
        background: #eef2f6 !important
        span
            color: #233539
    &_white
        background: #fff !important
        span
            color: #233539
    &_border-radius-top
        border-radius: 4px 4px 0 0 !important
    &_midnight-blue
        border: 1px solid #261C75 !important
        background: #fff !important
        padding: 0 18px !important
        span
            color: #261C75