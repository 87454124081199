body
    font-family: 'Lato', sans-serif
    background: #f5f5f5 !important

.main-box
    &__title
        font-size: 32px !important
        line-height: 38px !important
        letter-spacing: -1px !important
        color: #233539 !important
        margin: 0 !important
        padding: 15px 0 !important
        font-weight: 400 !important
        padding-top: 0 !important
        &_space
            margin: 0 0 0 25px !important
            margin-top: 5px !important
    &__bg
        background: #f5f5f5 !important
    &__content
        background: #FFFFFF
        box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.03)
        border-radius: 6px
        padding: 15px 25px
        &_border-radius-rb
            border-radius: 0 6px 6px 6px
    &__nomargin
        margin: 0 !important

    &__container
        &-fluid
            overflow: hidden

.fluid.container
    margin: 0 !important